import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private storage: Storage) {}

  
  async logoutInit() {
    console.log('Session Storage wird gelöscht?');
    await this.storage.create();
    await this.storage.remove('jwt');
    await this.storage.remove('sessionhash');
    await this.storage.remove('userid');
    await this.storage.remove('username');
    document.cookie = "token=0";
    document.cookie = "userid=0";
    document.cookie = "sessionhash=0";
    document.cookie = "username=0";

    window.location.href = '/start'
  }

}